import React from 'react'
import mobiscroll from '@mobiscroll/react'
import moment from 'moment'
import PageWrapper from '../../../helper/PageWrapper'
import Stepper from '../../helper/stepper/stepper'
import i18n from 'i18next'
import StaigeButton from '../../../components/styles/Button'
import arrowIcon from '../../../img/icons/arrow.svg'
import videocamIcon from '../../../img/icons/menu/camera.svg'
import footballIcon from '../../../img/icons/football.svg'
import shieldIcon from '../../../img/icons/shield.svg'
import windowIcon from '../../../img/icons/menu/window.svg'
import StaigeBadge from '../../../components/styles/Badge'
import StaigeSwitch from '../../../components/styles/Switch'
import tagIcon from '../../../img/icons/tag.svg'
import eyeIcon from '../../../img/icons/eye.svg'
import eventIcon from '../../../img/icons/event.svg'
import scheduleIcon from '../../../img/icons/schedule.svg'
import locatioOnIcon from '../../../img/icons/location_on.svg'
import localActivityIcon from '../../../img/icons/local_activity.svg'
import infoBlueIcon from '../../../img/icons/infoBlue.svg'
import playIcon from '../../../img/icons/play.svg'
import StaigeModal from '../../../components/styles/Modal'

import contractContainer, { ContractContainer } from '../../../services/contractContainer'
import { getLanguesFormat } from '../../helper/checkLanguages/checkLanguages'
import {
  ClubData,
  FreeTicket,
  VideoData,
  InstreamAd,
  CameraData,
  Contract,
  Role,
  Competition
} from '@soccerwatch/common'
import { CameraStep } from '../EventEditor/Camera'
import { EventStep } from '../EventEditor/EventStep'
import { TeamStep } from '../EventEditor/TeamsStep'
import { SponsorsStep } from '../EventEditor/SponsorStep'
import { PaywallContainer } from '../../../services/paywallContainer'
import { PaywallData } from '../Container/paywall'
import cameraContainer, { CameraContainer, withCameraContainer } from '../../../services/cameraContainer'
import { AdvertisementContainer, withAdvertisementContainer } from '../../../services/advertisementContainer'
import { EventTypes, eventTypes, stream } from './eventTypes'
import { UserContainer } from '../../../services/userContainer'
import { TeamData } from '../../../types/TeamData'
import { TeamContainer } from '../../../services/teamContainer'
import { ClubContainer } from '../../../services/clubContainer'
import { CityContainer } from '../../../services/cityContainer'
import { CompetitionContainer } from '../../../services/competitionContainer'
import { searchAndSortGetCallAs } from '../../../services/serviceHelper'
import { VideoContainer } from '../../../services/videoContainerContracts'
import { postPaywallPackage as postPaywall } from '../../../api/api-additional'
import { postVideoMeta, postVideoMetaById } from '../../../api/api-video'

import './MatchEditor.scss'
import { EmbedCode } from '../Container/embedCode'

type Paywall = {
  title?: string
  active: boolean
  type?: string | undefined
  description?: string
  priority?: number
  price?: number
  includes: Array<string>
  list?: Array<{ 'Trans-Nr': string; Nachname?: string }>
}

type MatchEditorProps = {
  cameras: Record<string, CameraData>
  videoContainer: VideoContainer
  userContainer: UserContainer
  clubContainer: ClubContainer
  teamContainer: TeamContainer
  cityContainer: CityContainer
  contractContainer: ContractContainer
  contractsChanging?: boolean
  competitionContainer: CompetitionContainer
  videoData: Array<VideoData>
  editId?: string
  onCloseOverlay: (something: boolean) => void
  reloadData: () => void
  reloadVideo: (videoId: number | string, reloadClubs?: boolean) => Promise<void>
  clubs: Record<string, ClubData>
  paywallContainer: PaywallContainer
  allDataLoaded: boolean
  createDate?: {
    start: string
    end: string
  }
  advertisementContainer: AdvertisementContainer
  cameraContainer: CameraContainer
  handleResetInitialized?: () => void
  goBack?: string
  history: any
}
export type MatchEditorState = {
  date: string
  season: string
  dateValue: string
  time: string
  timeValue: string
  duration: number
  platform: boolean
  privateActive?: boolean
  dropdownValue: {
    RowKey: string
    address?: string
  }
  dropdown: Array<{ value: string; address: string; id: string; hardwarePlatform?: string }>
  loadingPost: boolean
  existingMatch: boolean
  checkVideoType: string
  paywall: PaywallData
  paywallDataLoaded: boolean
  paywallActive: boolean
  paywallPackages: Array<unknown>
  clubHome?: ClubData
  clubGuest?: ClubData
  teamHome?: TeamData
  teamGuest?: TeamData
  competition?: Competition
  videoType?: string
  selectedField: string
  availableFields: Array<{ value: string; text: string }>
  selectedAdMaterials: Array<InstreamAd>
  activeStep: number
  prohibitAudioComments: boolean
  scoreboardShow: boolean
  gender?: string
  cameraMode: {
    streamType: string
    eventType: string
    cameraType: string
  }
  hardwarePlatform?: string
  disableTimeDate: boolean
  connection: string
  connectionActive: boolean
  connectionStringCheck: boolean
  connectionCheck: Array<string>
  nonCompetitiveTitle: string
  videoBufferSecs: number
  contractId?: string
  noContractUser: boolean
  cameraRecording: boolean
  recordingDone: boolean
  acceptEventTypes: Array<EventTypes>
  acceptEventModes: Array<string>
  video?: any
  paywallAGB: boolean
  changedStateCheck?: any
  embedCodeOpen: boolean
  tempClubGuest?: ClubData
}

class MatchEditor extends React.Component<MatchEditorProps, MatchEditorState> {
  initialized = false
  constructor(props: MatchEditorProps) {
    super(props)
    let ownerClubId: string | undefined
    let hardwarePlatform
    let dropdownValue: MatchEditorState['dropdownValue'] = {
      address: '',
      RowKey: ''
    }

    const dropdown = this.props.cameraContainer.state.loadingData
      ? []
      : this.props.cameraContainer.getCameraListForCurrentContract().map((cam, index) => {
          if (index === 0) {
            dropdownValue = { address: cam.address, RowKey: cam.RowKey }
            hardwarePlatform = cam.hardwarePlatform
            ownerClubId = cam.ownerClubId
          }
          return {
            value: cam.RowKey,
            address: cam.address || 'unknown',
            id: cam.RowKey,
            hardwarePlatform: cam.hardwarePlatform
          }
        })

    let dateValue, timeValue, date, time, duration
    date = moment().format('DD.MM.YYYY')
    const startTime = moment().add(10, 'minutes')
    time = moment().add(10, 'minutes').format('HH:mm')

    dateValue = moment().format(getLanguesFormat('date'))
    timeValue = startTime.format(getLanguesFormat('time'))
    let season = this.getSeasonFromDate()
    // new match with params
    if (this.props.createDate) {
      const start = moment(this.props.createDate.start)
      const end = moment(this.props.createDate.end)
      duration = moment.duration(end.diff(start)).asMinutes()
      date = start.format('DD.MM.YYYY')
      time = start.format('HH:mm')
      dateValue = start.format(getLanguesFormat('date'))
      timeValue = start.format(getLanguesFormat('time'))
      season = this.getSeasonFromDate(new Date(this.props.createDate.start))
    }

    const currentContract = this.props.contractContainer.getCurrentContract()
    let checkVideoType = 'soccer'
    let acceptEventTypes = eventTypes
    if (currentContract?.eventTypes && currentContract.eventTypes.length > 0) {
      const tempEventTypes = eventTypes.filter((event) => currentContract?.eventTypes.includes(event.value))
      if (tempEventTypes.length > 0) {
        acceptEventTypes = tempEventTypes
        checkVideoType = acceptEventTypes[0].value
      }
    }

    let checkAcceptModes = ['broadcast', 'training']
    if (currentContract?.eventModes && currentContract.eventModes.length > 0) {
      let tempEventModes = currentContract.eventModes
      if (!tempEventModes.includes('broadcast')) {
        checkAcceptModes = ['broadcast', ...tempEventModes]
      } else [(checkAcceptModes = [...tempEventModes])]
    }

    this.state = {
      date: date,
      season,
      dateValue: dateValue,
      time: time,
      timeValue: timeValue,
      duration: duration || 120,
      platform: false,
      privateActive: true,
      dropdownValue: dropdownValue,
      dropdown,
      loadingPost: false,
      existingMatch: false,
      checkVideoType: checkVideoType,
      acceptEventTypes: acceptEventTypes,
      acceptEventModes: checkAcceptModes,
      paywall: {
        price: 0,
        freeTickets: []
      },
      paywallDataLoaded: false,
      paywallActive: false,
      paywallPackages: [],
      selectedAdMaterials: [],
      clubHome: undefined,
      clubGuest: undefined,
      competition: undefined,
      activeStep: 0,
      prohibitAudioComments: false,
      scoreboardShow: true,
      selectedField: '',
      availableFields: [],
      gender: '',
      cameraMode: this.getCameraMode(dropdownValue.RowKey, undefined, checkVideoType),
      hardwarePlatform: hardwarePlatform,
      disableTimeDate: false,
      connection: '',
      connectionActive: false,
      connectionStringCheck: true,
      connectionCheck: ['rtmp://', 'gs://', 's3://', 'srt://'],
      nonCompetitiveTitle: '',
      videoBufferSecs: 60,
      noContractUser: false,
      cameraRecording: false,
      recordingDone: false,
      paywallAGB: false,
      embedCodeOpen: false
    }
  }

  private refConfirmReturnModal = React.createRef<any>()
  private refFreeticketList = React.createRef<mobiscroll.PopupComponent>()

  getSeasonFromDate = (date = new Date()) => {
    const shortYear = +String(date.getFullYear()).substring(2)
    const month = date.getMonth()
    if (month < 7) {
      return String(shortYear - 1) + '/' + String(shortYear)
    } else {
      return String(shortYear) + '/' + String(shortYear + 1)
    }
  }

  async trySetInitialHomeClub(ownerClubId: string) {
    // search club for set initial HomeClub

    if (ownerClubId !== undefined) {
      const club = await this.props.clubContainer.getClubById(ownerClubId)
      if (club) {
        const teamHome = (await this.props.teamContainer.getTeamsOfClub(club.RowKey))[0]
        this.setState({
          clubHome: club,
          teamHome: teamHome
        })
      } else {
        console.error('Could not find ClubId', ownerClubId, 'in ClubContainer. This should not happen!')
      }
    }
  }

  checkEventTypeOfNonCompetitive(): boolean {
    return (
      this.state.cameraMode.eventType === 'church' ||
      this.state.cameraMode.eventType === 'musical' ||
      this.state.cameraMode.eventType === 'horses' ||
      this.state.cameraMode.eventType === 'dressage'
    )
  }

  checkSpecialRole = () => {
    const ifRole = 'videoConnectionEditor'
    return Boolean(this.props.userContainer.state.user?.role?.find((role) => role === ifRole))
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.reloadSite()
    this.props.contractContainer.setLockContractSelect(true)
  }

  componentWillUnmount() {
    this.props.contractContainer.setLockContractSelect(false)
  }

  componentDidUpdate(prevProp: MatchEditorProps) {
    if (this.props.editId) {
      if (!prevProp.allDataLoaded !== this.props.allDataLoaded && !this.initialized) {
        this.initialized = true
        this.reloadSite()
      }
    } else {
      if (prevProp.contractsChanging && !this.props.contractsChanging) {
        let ownerClubId: string | undefined
        let hardwarePlatform
        let dropdownValue: MatchEditorState['dropdownValue'] = {
          address: '',
          RowKey: ''
        }

        const list = this.props.cameraContainer.state.loadingData
          ? []
          : this.props.cameraContainer.getCameraListForCurrentContract()
        const dropdown = list.map((cam, index) => {
          if (index === 0) {
            dropdownValue = { address: cam.address, RowKey: cam.RowKey }
            hardwarePlatform = cam.hardwarePlatform
            ownerClubId = cam.ownerClubId
          }
          return {
            value: cam.RowKey,
            address: cam.address || 'unknown',
            id: cam.RowKey,
            hardwarePlatform: cam.hardwarePlatform
          }
        })

        this.getAvailableFields(dropdownValue.RowKey)
        this.setState({ dropdown, dropdownValue, hardwarePlatform, paywallDataLoaded: true })
        this.setState({
          changedStateCheck: JSON.stringify(this.state)
        })
      }
    }

    if (prevProp.contractContainer.state.loadingData && !this.props.contractContainer.state.loadingData) {
      if (this.props.contractContainer.state.contracts[0]) {
        this.setState({ contractId: this.props.contractContainer.state.contracts[0].RowKey })
      } else {
        this.setState({ noContractUser: true })
      }
    }

    // set homeclub when all data had to be reloaded
    if (
      Object.keys(this.props.clubs).length > 0 &&
      Object.keys(this.props.cameras).length > 0 &&
      this.state.clubHome === undefined
    ) {
      this.setHomeTeam(undefined, false)
    }
  }

  setPaywallAGB = (value: boolean) => {
    this.setState({
      paywallAGB: value
    })
  }

  setCompetition = async (competition?: Competition) => {
    if (!competition?.RowKey) {
      competition = undefined
    }
    this.setState({ competition })
  }

  setHomeTeam = async (swcsId: string | undefined, soloSetState: boolean) => {
    let clubHome
    let ownerClubId: undefined | string = undefined
    const RowKey = swcsId || this.state.dropdownValue.RowKey
    if (RowKey) {
      const camera = this.props.cameras[RowKey]
      if (camera) {
        ownerClubId = camera.ownerClubId
      } else {
        // TODO: Do we need to handle the Else Case here?
        console.error('<MatchEditor:SetHomeTeam>Could not Find Camera', RowKey, '. This should not happen.')
      }
    }
    if (ownerClubId !== undefined) {
      const club = await this.props.clubContainer.getClubById(ownerClubId)
      if (club) {
        clubHome = club
      } else {
        console.error('ClubContainer was unable to fetch ClubId', ownerClubId, '. This should not happen!')
      }
    }

    if (clubHome !== undefined) {
      this.setState({
        clubHome: clubHome,
        teamHome: this.props.teamContainer.getTeamsOfClubSync(clubHome.RowKey)[0],
        clubGuest: this.state.cameraMode.streamType === 'training' ? clubHome : undefined,
        teamGuest:
          this.state.cameraMode.streamType === 'training'
            ? this.props.teamContainer.getTeamsOfClubSync(clubHome.RowKey)[0]
            : undefined
      })
    }
    if (soloSetState && ownerClubId === '-1') {
      this.setState({
        clubHome: undefined
      })
    }
  }

  getCameraMode = (swcsId: string, editObj?: VideoData, videoType?: string) => {
    let videoProcessingDefault = 'cameraman'
    const ownerClub = this.props.cameraContainer
      .getCameraListForCurrentContract()
      .filter((itm) => itm.RowKey === swcsId)

    // check if ip camera, if yes then video processing only on passthrough
    if (ownerClub[0]?.hardwarePlatform === 'ip') {
      videoProcessingDefault = 'passthrough'
    }
    if (ownerClub[0]?.defaultVideoProcessing === 'passthrough') {
      videoProcessingDefault = ownerClub[0].defaultVideoProcessing
    }

    if (editObj) {
      // get data from video
      const eventType = editObj.eventType || 'soccer'
      const contentType = editObj.contentType || 'broadcast'
      const found = stream.find((element) => element.value === editObj.videoProcessing)
      const videoProcessing = found ? String(editObj.videoProcessing) : videoProcessingDefault

      return {
        streamType: contentType,
        eventType: eventType,
        cameraType: videoProcessing
      }
    } else {
      // get eventType from camera
      let defaultEventType = videoType || 'soccer'

      if (ownerClub[0]?.defaultEventType) {
        defaultEventType = ownerClub[0].defaultEventType
      }

      return {
        streamType: 'broadcast',
        eventType: defaultEventType,
        cameraType: videoProcessingDefault
      }
    }
  }

  handlePaywallActive = () => {
    this.setState({
      paywallActive: !this.state.paywallActive
    })
  }

  reloadSite = async () => {
    if (this.props.editId) {
      this.initializeSelectedAdMaterials()
      await this.props.reloadVideo(this.props.editId)
      let editData = this.props.videoData.find((video) => video.RowKey === this.props.editId)
      if (!editData) {
        throw new Error('Video was not found!')
      }
      let dateValue = moment(editData.expectedStartTime).format(getLanguesFormat('date'))
      let timeValue = moment(editData.expectedStartTime).format(getLanguesFormat('time'))

      // check special role for: connection input
      let connectionActive = false
      let check = []
      if (editData.connection && editData.connection?.length > 0 && this.checkSpecialRole()) {
        connectionActive = true
        if (editData.connection.indexOf('gs://sw-sc-de-shared/') > -1) {
          connectionActive = false
        }
        const valueCheck = this.state.connectionCheck
        const connectionString = editData.connection
        check = valueCheck.filter((string) => connectionString.includes(string))
      }

      const clubHome = await this.props.clubContainer.getClubById(String(editData.clubAId))
      const clubGuest = await this.props.clubContainer.getClubById(String(editData.clubBId))
      const conditionalData: { competition?: Competition } = {}
      if (editData.competitionId) {
        conditionalData.competition = await this.props.competitionContainer.getCompetitionById(
          editData.competitionId
        )
      }

      const teamHome =
        editData.clubATeamId && editData.clubATeamId !== 'undefined' && editData.clubATeamId !== '-1'
          ? await this.props.teamContainer.getTeamOfClubById(editData.clubATeamId, clubHome.RowKey)
          : undefined
      const teamGuest =
        editData.clubBTeamId && editData.clubBTeamId !== 'undefined' && editData.clubBTeamId !== '-1'
          ? await this.props.teamContainer.getTeamOfClubById(editData.clubBTeamId, clubGuest.RowKey)
          : undefined

      let cameraRecording = false
      let recordingDone = false
      //@ts-ignore Types dont seem to have calculated States
      if ((editData.state === 'live' || editData.state === 'Mission running') && editData.userStream) {
        cameraRecording = true
      } else if (editData.state === 'done' && Date.now() > new Date(editData.expectedStartTime).getTime()) {
        recordingDone = true
      }
      const season = this.getSeasonFromDate(new Date(editData.expectedStartTime))
      this.setState({
        cameraRecording,
        recordingDone,
        connectionStringCheck: check.length <= 0,
        date: moment(editData.expectedStartTime).format('DD.MM.YYYY'),
        season,
        dateValue: dateValue,
        time: moment(editData.expectedStartTime).format('HH:mm'),
        timeValue: timeValue,
        duration: editData.durationMin,
        privateActive: editData.privateActive,
        platform: editData.adFree ?? false,
        videoType: editData.videoType,
        paywallActive: editData.paywall || false,
        dropdownValue: { RowKey: editData.swcsID, address: editData.address },
        clubHome,
        clubGuest,
        teamHome,
        teamGuest,
        selectedField: editData.fieldCorners || '',
        prohibitAudioComments: editData.prohibitAudioComments ? editData.prohibitAudioComments : false,
        scoreboardShow: editData.hideScoreboard ? !editData.hideScoreboard : true,
        gender: editData.gender || '',
        cameraMode: this.getCameraMode(editData.swcsID, editData, this.state.checkVideoType),
        disableTimeDate: !moment().isBefore(moment(editData.expectedStartTime).subtract(10, 'minutes')),
        connection: editData.connection || '',
        connectionActive: connectionActive,
        nonCompetitiveTitle: editData.titleOverwriteC || '',
        videoBufferSecs: editData.videoBufferSecs || 60,
        video: editData,
        ...conditionalData
      })

      await this.getAvailableFields(editData.swcsID)
      // check paywall packages
      this.getPaywallPackages(editData.RowKey)
    } else {
      if (this.state.dropdownValue?.RowKey) {
        await this.getAvailableFields(this.state.dropdownValue.RowKey)
        this.setState({ paywallDataLoaded: true })
      }
    }

    this.setState({
      changedStateCheck: JSON.stringify(this.state)
    })
  }

  getPaywallPackages = (id: string) => {
    this.props.paywallContainer.setPayWallPackagesByMatchId(Number(id)).then((res) => {
      if (res.length === 0) {
        this.setState({ paywallDataLoaded: true })
        return
      }

      if (res.length > 0) {
        let price = 5
        res.map((obj) => {
          if (obj.active && obj.type === undefined && obj.price !== undefined) {
            price = obj.price
          }
        })

        this.setState({
          paywall: {
            price: price,
            freeTickets: this.props.paywallContainer.state.paywallFreeTickets
          },
          paywallDataLoaded: true,
          paywallPackages: res,
          paywallAGB: true
        })
      }
    })
    return null
  }

  postPaywallPackage = async (body: Paywall) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    return await postPaywall(body, callAs)
  }

  handleDate = (dateString: string) => {
    let date = dateString
    const season = this.getSeasonFromDate(new Date(date))
    if (String(dateString).includes('/')) {
      date = moment(dateString, 'MM/DD/YYYY').format('DD.MM.YYYY')
    }

    this.setState({
      date: date,
      season,
      dateValue: dateString
    })
  }

  handleTime = (timeString: string) => {
    let time = timeString
    if (String(timeString).includes('PM') || String(timeString).includes('AM')) {
      time = moment(timeString, 'hh:mm A').format('HH:mm')
    }
    this.setState({
      time: time,
      timeValue: timeString
    })
  }

  handleDisabledAudioComments = () => {
    this.setState({
      prohibitAudioComments: !this.state.prohibitAudioComments
    })
  }

  handleDisabledScoreboard = () => {
    this.setState({
      scoreboardShow: !this.state.scoreboardShow
    })
  }

  handleDropdown = async (RowKey: string, address: string) => {
    const swcsId = RowKey
    this.setState({
      selectedField: '',
      dropdownValue: { address: address, RowKey: swcsId },
      cameraMode: this.getCameraMode(swcsId, undefined, this.state.checkVideoType)
    })

    this.getAvailableFields(swcsId)
    // set Home team only by new recordings
    if (!this.props.editId) {
      this.setHomeTeam(swcsId, true)
    }
  }

  getAvailableFields = async (swcsId: string) => {
    if (!swcsId || swcsId === '') {
      return
    }
    const fieldNames = (await cameraContainer.getFieldNamesForCamera(swcsId)).map((n) => {
      return n === 'default' ? { value: '', text: 'Standard' } : { value: n, text: n }
    })
    this.setState({ availableFields: fieldNames })
  }

  handleSetFieldName = async (value: string) => {
    const selectedField = value
    this.setState({ selectedField })
  }

  postMeta = async (data: Partial<VideoData>) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    return postVideoMeta(data, callAs)
  }

  postMetaVideoId = async (data: Partial<VideoData>, id: VideoData['matchId']) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    return postVideoMetaById(data, id, callAs)
  }

  generateSaveHandler = (isNew: boolean) => async () => {
    if (!this.state.date || !this.state.time) {
      await mobiscroll.toast({ color: 'error', message: i18n.t('recordingPlan.toastText.errDate') })
      return
    }

    if (isNew && moment().isAfter(moment(this.state.date + ' ' + this.state.time, 'DD.MM.YYYY HH:mm'))) {
      await mobiscroll.toast({ color: 'error', message: i18n.t('recordingPlan.toastText.startIsAfter') })
      this.setState({
        activeStep: 0
      })
      return
    }

    const contract = this.props.contractContainer.getCurrentContract()
    if (!contract) {
      console.error('<MatchEditor> No Contract Selected. This should not happen.')
      await mobiscroll.toast({ color: 'error', message: i18n.t('recordingPlan.toastText.noContract') })
      return
    }

    this.setState({ loadingPost: true })
    const ownerClubId = this.state.dropdownValue.RowKey

    let checkVideoType = this.state.cameraMode.eventType
    if (this.state.cameraMode.cameraType !== 'cameraman') {
      checkVideoType = this.state.cameraMode.cameraType
    }

    // check special role for: connection input
    let connectionActive = false
    if (this.checkSpecialRole() && this.state.connectionActive) {
      connectionActive = true
    }

    const { match, oldRowKey } = isNew
      ? this.generateNewMatch(checkVideoType, contract, ownerClubId, connectionActive)
      : this.generateEditedMatch(checkVideoType, contract, ownerClubId, connectionActive)

    if (Object.values(match).filter((v) => v !== undefined).length === 0 && oldRowKey) {
      // Nothing changed, Just Update the Selected AdMaterials (since they are not saved inside the Match)
      this.saveSelectedAdMaterials(oldRowKey)
      return
    }
    let response: { RowKey: string } | undefined

    try {
      if (isNew || oldRowKey === undefined) {
        response = await this.postMeta(match)
      } else {
        response = await this.postMetaVideoId(match, oldRowKey)
      }

      if (!response) {
        return
      }

      if (match.paywall) {
        const payPackages = this.createNewPaywall(response.RowKey)
        // post ticket package
        this.postPaywallPackage(payPackages)
      }

      if (this.state.paywall.freeTickets.length > 0) {
        // Generates / expands / reduces list of free tickets. If match
        // is being edited and nothing changed, it returns undefined
        const payPackages = this.createNewFreePasses(response.RowKey)

        // post freetickets
        if (payPackages) {
          await Promise.all([this.postPaywallPackage(payPackages), this.props.paywallContainer.refreshData()])
        }
      }
      await this.saveSelectedAdMaterials(response.RowKey)
    } catch (err: any) {
      if (err.response?.data?.status === 'out of streaming minutes') {
        const msg = i18n.t('recordingPlan.toastText.outOfStreamingMinutes')
        await mobiscroll.toast({ color: 'warning', message: msg })
        this.setState({ loadingPost: false })
        return
      }

      // Something went wrong along the Way
      const msg = i18n.t('recordingPlan.toastText.errSave')
      await mobiscroll.toast({
        color: 'warning',
        message: msg
      })
      this.setState({ loadingPost: false })
      throw new Error('Request was not successful')
    }

    const msg = i18n.t(`recordingPlan.toastText.${isNew ? 'succdessNew' : 'succsessSave'}`)
    let now = new Date()
    let nowPlustenMin = new Date(now.getTime() + 15 * 60000).getTime()
    //@ts-ignore
    if (isNew && nowPlustenMin >= match.expectedStartTime) {
      await mobiscroll.toast({ message: i18n.t('recordingPlan.toastText.underTenMin'), duration: 8000 })
    } else {
      await mobiscroll.toast({
        message: msg,
        duration: 4000
      })
    }

    if (isNew || oldRowKey === undefined) {
      this.props.reloadData()
    } else {
      await this.props.reloadVideo(oldRowKey)
    }

    if (this.props.handleResetInitialized !== undefined) {
      this.props.handleResetInitialized()
    }

    this.props.onCloseOverlay(false)
  }

  private createNewPaywall = (RowKey: string) => ({
    title: 'Einzel-Zugang',
    active: true,
    type: undefined,
    description: 'Schalte dich fÃ¼r dieses Spiel frei.',
    price: this.state.paywall.price,
    includes: [`video-${RowKey}`]
  })

  private createNewFreePasses = (RowKey: string) => {
    // Create comparable (===) values from old and new tickets array
    // Then, only update tickets when necessary
    const mapToString = (tickets: Array<FreeTicket>) => tickets.map((ticket) => ticket['Trans-Nr']).join(';')
    const oldTicketsString = mapToString(this.props.paywallContainer.state.paywallFreeTickets)
    const newTicketsString = mapToString(this.state.paywall.freeTickets)

    if (oldTicketsString !== newTicketsString) {
      return {
        title: 'Freikarte',
        active: true,
        type: 'free',
        description: 'Freikarte',
        includes: [`video-${RowKey}`],
        list: this.state.paywall.freeTickets
      }
    } else {
      return undefined
    }
  }

  generateNewMatch = (
    checkVideoType: string,
    contract: Contract,
    ownerClubId?: string,
    connectionActive?: boolean
  ) => {
    // team
    const { clubHome, clubGuest, teamHome, teamGuest } = this.state

    let titleOverwriteA = undefined
    let titleOverwriteC = undefined

    const connection = connectionActive ? { connection: this.state.connection } : {}

    const addMatch: Partial<VideoData> = {
      ...connection,
      state: 'created',
      clubAName: clubHome?.name ?? 'ClubA',
      clubBName: clubGuest?.name ?? 'ClubB',
      clubATeam: teamHome?.name ?? 'TeamA',
      clubBTeam: teamGuest?.name ?? 'TeamB',
      clubATeamId: teamHome?.RowKey ?? undefined,
      clubBTeamId: teamGuest?.RowKey ?? undefined,
      teamAAge: teamHome?.age,
      teamBAge: teamGuest?.age,
      titleOverwriteA: titleOverwriteA,
      titleOverwriteC: titleOverwriteC,
      durationMin: this.state.duration,
      expectedStartTime: new Date(
        moment(this.state.date + ' ' + this.state.time, 'DD.MM.YYYY HH:mm').toISOString()
      ).getTime(),
      clubAId: clubHome?.RowKey ?? '-1',
      clubBId: clubGuest?.RowKey ?? '-1',
      swcsID: this.state.dropdownValue.RowKey,
      field: this.state.dropdownValue.address,
      videoType: checkVideoType,
      active: true,
      platformIgnore: this.state.privateActive ?? false,
      privateActive: this.state.privateActive ?? false, // only coachingtool
      adFree: this.state.platform, // only platfrom
      paywall: this.state.paywallActive,
      prohibitAudioComments: this.state.prohibitAudioComments,
      hideScoreboard: !this.state.scoreboardShow,
      gender: this.state.gender,
      videoFormat: this.state.hardwarePlatform,
      eventType: this.state.cameraMode.eventType,
      contentType: this.state.cameraMode.streamType,
      contractId: contract.disabledSetContractId === true ? undefined : contract.RowKey,
      videoProcessing:
        this.state.cameraMode.cameraType === 'cameraman'
          ? this.state.cameraMode.eventType
          : this.state.cameraMode.cameraType,
      videoBufferSecs: this.state.videoBufferSecs,
      competitionId: this.state.competition?.RowKey
    }

    if (this.checkEventTypeOfNonCompetitive()) {
      addMatch.clubAId = '0'
      addMatch.clubBId = '0'
      addMatch.clubAName = 'Staige'
      addMatch.clubBName = 'Staige'
      addMatch.titleOverwriteA = 'Staige'
      addMatch.titleOverwriteC = this.state.nonCompetitiveTitle
    }

    if (this.state.selectedField !== '') {
      addMatch.fieldCorners = this.state.selectedField
    }

    return { match: addMatch, oldRowKey: undefined }
  }

  generateEditedMatch = (
    checkVideoType: string,
    contract: Contract,
    ownerClubId?: string,
    connectionActive?: boolean
  ) => {
    // team
    const { clubHome, clubGuest, teamHome, teamGuest, competition } = this.state
    const teamHomeRowKey = clubHome ? clubHome.RowKey : ownerClubId
    const teamGuestRowKey = clubGuest ? clubGuest.RowKey : ownerClubId

    let oldData = this.props.videoData.find((video) => video.RowKey === this.props.editId)
    if (!oldData && this.state.video && this.state.video.RowKey === this.props.editId) {
      oldData = this.state.video
    }

    if (!oldData) {
      throw new Error('Old data not found!')
    }

    const connection = connectionActive
      ? oldData.connection !== this.state.connection
        ? this.state.connection
        : undefined
      : undefined
    const clubAId = oldData.clubAId !== teamHomeRowKey ? teamHomeRowKey : undefined
    const clubBId = oldData.clubBId !== teamGuestRowKey ? teamGuestRowKey : undefined
    const clubA = oldData.clubAName !== clubHome?.name ? clubHome?.name : undefined
    const clubB = oldData.clubBName !== clubGuest?.name ? clubGuest?.name : undefined
    const teamNameHome = oldData.clubATeam !== teamHome?.name ? teamHome?.name : undefined
    const teamNameGuest = oldData.clubBTeam !== teamGuest?.name ? teamGuest?.name : undefined
    const teamAgeHome = oldData.teamAAge !== teamHome?.age ? teamHome?.age : undefined
    const teamAgeGuest = oldData.teamBAge !== teamGuest?.age ? teamGuest?.age : undefined
    const clubATeamId = oldData.clubATeamId !== teamHome?.RowKey ? teamHome?.RowKey : undefined
    const clubBTeamId = oldData.clubBTeamId !== teamGuest?.RowKey ? teamGuest?.RowKey : undefined
    const competitionId = oldData.competitionId !== competition?.RowKey ? competition?.RowKey : undefined

    const videoProcessing =
      this.state.cameraMode.cameraType === 'cameraman'
        ? this.state.cameraMode.eventType
        : this.state.cameraMode.cameraType

    const duration =
      Number(oldData.durationMin) !== Number(this.state.duration) ? Number(this.state.duration) : undefined
    const oldStartTime = new Date(oldData.expectedStartTime).getTime()
    const newStartTime = new Date(
      moment(this.state.date + ' ' + this.state.time, 'DD.MM.YYYY HH:mm').toISOString()
    ).getTime()
    const startTime =
      oldStartTime !== newStartTime
        ? new Date(
            moment(this.state.date + ' ' + this.state.time, 'DD.MM.YYYY HH:mm').toISOString()
          ).getTime()
        : undefined
    const swcsID = this.state.dropdownValue.RowKey
    const field = swcsID !== undefined ? this.state.dropdownValue.address : undefined
    const videoType = checkVideoType !== oldData.videoType ? checkVideoType : undefined
    const nonCompetitiveTitle =
      this.state.nonCompetitiveTitle !== oldData.titleOverwriteC ? this.state.nonCompetitiveTitle : undefined
    const privateActive =
      this.state.privateActive !== oldData.privateActive ? this.state.privateActive : undefined

    const adFree = this.state.platform !== oldData.adFree ? this.state.platform : undefined
    const disabledAudioComments =
      this.state.prohibitAudioComments !== oldData.prohibitAudioComments
        ? this.state.prohibitAudioComments
        : undefined

    const disabledScoreboard =
      this.state.scoreboardShow !== !oldData.hideScoreboard ? !this.state.scoreboardShow : undefined

    const videoBufferSecs =
      this.state.videoBufferSecs !== oldData.videoBufferSecs ? this.state.videoBufferSecs : undefined
    const editMatch: Partial<VideoData> = {
      clubAName: clubA,
      clubBName: clubB,
      clubATeam: teamNameHome,
      clubBTeam: teamNameGuest,
      clubAId: clubAId,
      clubBId: clubBId,
      clubATeamId,
      clubBTeamId,
      durationMin: duration,
      expectedStartTime: startTime,
      swcsID: swcsID,
      field: field,
      videoType: videoType,
      platformIgnore: privateActive,
      privateActive: privateActive, // only coachingtool
      adFree: adFree, // only platfrom
      prohibitAudioComments: disabledAudioComments,
      hideScoreboard: disabledScoreboard,
      hideScoreBoardHighlight: disabledScoreboard,
      paywall: this.state.paywallActive,
      teamAAge: teamAgeHome,
      teamBAge: teamAgeGuest,
      gender: oldData.gender !== this.state.gender ? this.state.gender : undefined,
      videoFormat:
        oldData.videoFormat !== this.state.hardwarePlatform ? this.state.hardwarePlatform : undefined,
      eventType:
        oldData.eventType !== this.state.cameraMode.eventType ? this.state.cameraMode.eventType : undefined,
      contentType:
        oldData.contentType !== this.state.cameraMode.streamType
          ? this.state.cameraMode.streamType
          : undefined,
      contractId: oldData.contractId
        ? oldData.contractId
        : contract.disabledSetContractId === true
        ? undefined
        : contract.RowKey,
      videoProcessing: oldData.videoProcessing !== videoProcessing ? videoProcessing : undefined,
      connection: connection,
      customStartTime: startTime !== undefined ? true : undefined,
      titleOverwriteC: nonCompetitiveTitle,
      videoBufferSecs: videoBufferSecs,
      competitionId
    }

    if (this.state.selectedField !== '') {
      editMatch.fieldCorners = this.state.selectedField
    }

    return { match: editMatch, oldRowKey: oldData.RowKey }
  }

  handleAccept = this.generateSaveHandler(true)
  handleAcceptEdit = this.generateSaveHandler(false)

  handlePrivateActive = (value: boolean, special?: boolean) => {
    if (special) {
      this.setState({
        connectionActive: true
      })
      return
    }
    this.setState({
      privateActive: value,
      connectionActive: false
    })
  }

  setActiveStep = (value: number) => {
    window.scrollTo(0, 0)
    this.setState({
      activeStep: value
    })
  }

  setDuration = (value: number) => {
    this.setState({
      duration: value
    })
  }

  handleRecordState = (stateName: string, value: string) => {
    this.setState({
      ...this.state,
      [stateName]: value
    })
  }

  setPaywall = (price: number, freeTickets: Array<FreeTicket>) => {
    this.setState({
      paywall: {
        price: price,
        freeTickets
      }
    })
  }

  setSelectedAdMaterialIDs = (newAdMaterials: Array<InstreamAd>) => {
    this.setState({
      selectedAdMaterials: newAdMaterials
    })
  }

  initializeSelectedAdMaterials = () => {
    const onAdvertisementUpdate = () => {
      const adMaterials = this.props.advertisementContainer.getAdMaterialsForCurrentContract()
      if (adMaterials.length > 0) {
        const usedMaterials = adMaterials.filter((adMaterial) => {
          return (
            this.props.editId &&
            adMaterial.matchSponsor &&
            adMaterial.matchSponsor.includes(this.props.editId)
          )
        })
        this.props.advertisementContainer.unsubscribe(onAdvertisementUpdate)

        this.setState({
          selectedAdMaterials: usedMaterials
        })
      }

      // Required by unstated
      return Promise.resolve()
    }
    this.props.advertisementContainer.subscribe(onAdvertisementUpdate)
    onAdvertisementUpdate()
  }

  saveSelectedAdMaterials = async (videoID: string) => {
    const adMaterials = this.props.advertisementContainer.getAdMaterialsForCurrentContract()

    const changedMaterials = adMaterials.reduce((changedMaterials, adMaterial) => {
      const shouldBeIncludedInDistribution =
        this.state.selectedAdMaterials.find((currMaterial) => currMaterial.RowKey === adMaterial.RowKey) !==
        undefined

      const newVideoIDs = adMaterial.matchSponsor
        ? adMaterial.matchSponsor.filter((id: string) => id !== videoID)
        : []
      if (shouldBeIncludedInDistribution) {
        newVideoIDs.push(String(videoID))
      }

      // Video ID was either removed or added, but multiple video
      // IDs are never modified at once.
      if (adMaterial.matchSponsor?.length !== newVideoIDs?.length) {
        changedMaterials.push({
          ...adMaterial,
          matchSponsor: newVideoIDs
        })
      }

      return changedMaterials
    }, [] as Array<InstreamAd>)

    for (const changedMaterial of changedMaterials) {
      await this.props.advertisementContainer.updateAdMaterial(changedMaterial)
    }
  }

  goToPreviousStep = () => {
    this.setActiveStep(this.state.activeStep - 1)
  }
  goToNextStep = () => {
    this.setActiveStep(this.state.activeStep + 1)
  }

  onSetStreamType = (type: string) => {
    if (type === 'training') {
      let ownerClubId: any
      const camera = this.props.cameras[this.state.dropdownValue.RowKey]
      if (camera) {
        ownerClubId = camera.ownerClubId
      }

      let clubGuest = undefined
      if (ownerClubId !== undefined) {
        const club = this.props.clubs[ownerClubId]
        if (club) {
          clubGuest = club
        } else {
          console.error('Could not find ClubId', ownerClubId, 'in ClubContainer. This should not happen!')
        }
      }

      // check defaultVideoProcessing
      let cameraType = 'quarterBoxPanorama'
      if (camera.defaultVideoProcessing === 'passthrough') {
        cameraType = camera.defaultVideoProcessing
      }

      this.setState({
        cameraMode: { ...this.state.cameraMode, streamType: type, cameraType: cameraType },
        clubGuest: clubGuest !== undefined ? clubGuest : undefined,
        tempClubGuest: this.state.clubGuest
      })
    } else {
      // check defaultVideoProcessing
      const camera = this.props.cameras[this.state.dropdownValue.RowKey]
      let cameraType = 'cameraman'
      if (camera.defaultVideoProcessing === 'passthrough') {
        cameraType = camera.defaultVideoProcessing
      }

      this.setState({
        cameraMode: { ...this.state.cameraMode, streamType: type, cameraType: cameraType },
        clubGuest: this.state.tempClubGuest
      })
    }
  }

  onSetEventType = (type: string) => {
    const newCameraMode = { ...this.state.cameraMode, eventType: type }
    this.setState({
      cameraMode: newCameraMode
    })
  }

  onSetCameraType = (type: string) => {
    const swcsId = this.state.dropdownValue.RowKey
    const ownerClub = this.props.cameraContainer
      .getCameraListForCurrentContract()
      .filter((itm) => itm.RowKey === swcsId)

    // check defaultVideoProcessing
    if (ownerClub[0]?.defaultVideoProcessing === 'passthrough') {
      type = ownerClub[0].defaultVideoProcessing
    }

    const newCameraMode = { ...this.state.cameraMode, cameraType: type }
    this.setState({
      cameraMode: newCameraMode
    })
  }

  onSetConnection = (value: string) => {
    const connection = value.trim()
    const valueCheck = this.state.connectionCheck
    const check: string[] = valueCheck.filter((string) => value.includes(string))

    this.setState({
      connection: connection,
      connectionStringCheck: check.length <= 0
    })
  }

  onSetVideoBufferSecs = (value: number) => {
    this.setState({
      videoBufferSecs: value
    })
  }

  onSetNonCompetitive = (value: string) => {
    this.setState({
      nonCompetitiveTitle: value
    })
  }

  onSetClub = async (club: ClubData, isHomeClub: boolean) => {
    if (!club.RowKey) {
      club.RowKey = club.id
    }
    const teams = await this.props.teamContainer.getTeamsOfClub(club.RowKey)
    if (isHomeClub) {
      if (club.RowKey !== this.state.clubHome?.RowKey) {
        this.setState({ clubHome: club, teamHome: teams[0] })
      }
    } else {
      if (club.RowKey !== this.state.clubGuest?.RowKey) {
        this.setState({ clubGuest: club, teamGuest: teams[0] })
      }
    }
  }

  onSetTeam = (team: TeamData, isHomeTeam: boolean) => {
    if (!team.RowKey) {
      // @ts-ignore
      team.RowKey = team.id
    }
    if (isHomeTeam) {
      this.setState({ teamHome: team })
    } else {
      this.setState({ teamGuest: team })
    }
  }

  handleDeActivate = async (id: string, contractId: string, active: boolean) => {
    const currentContract = this.props.contractContainer.getCurrentContract()
    const state = active ? { state: 'done', active: false } : { state: 'created', active: true }
    const modifiedVideo = {
      ...state,
      RowKey: id,
      contractId: contractId || currentContract?.RowKey
    }
    //@ts-ignore
    await this.props.videoContainer.updateVideo(modifiedVideo)
    await this.reloadSite()
  }

  renderCheckBox = (
    isStart: boolean,
    currentContractMatchNotAllow: boolean,
    contractNotSet: boolean,
    label: string,
    displayNone: boolean
  ) => {
    return (
      <div
        style={displayNone ? { display: 'none' } : {}}
        className={`top-quickInfo ${isStart ? 'top-quickInfoDisable' : ''}`}
      >
        {isStart ? <img className='quickInfo-recIcon' src={playIcon} /> : null}
        <StaigeSwitch
          disabled={(currentContractMatchNotAllow && !contractNotSet) || isStart}
          checked={this.state.video.active}
          onClick={() =>
            this.handleDeActivate(
              this.state.video.RowKey,
              this.state.video.contractId,
              this.state.video.active
            )
          }
          size='medium'
          label={label}
        />
      </div>
    )
  }

  checkChanges = () => {
    const oldState = JSON.parse(this.state.changedStateCheck)
    const actualState = {
      duration: this.state.duration,
      date: this.state.date,
      time: this.state.time,
      dropdownValue: this.state.dropdownValue,
      cameraMode: this.state.cameraMode,
      privateActive: this.state.privateActive,
      platform: this.state.platform,
      paywallActive: this.state.paywallActive,
      prohibitAudioComments: this.state.prohibitAudioComments,
      scoreboardShow: this.state.scoreboardShow,
      gender: this.state.gender,
      hardwarePlatform: this.state.hardwarePlatform,
      videoBufferSecs: this.state.videoBufferSecs,
      competition: this.state.competition,
      teamGuest: this.state.teamGuest,
      clubGuest: this.state.clubGuest
    }
    const oldStateCheck = {
      duration: oldState.duration,
      date: oldState.date,
      time: oldState.time,
      dropdownValue: oldState.dropdownValue,
      cameraMode: oldState.cameraMode,
      privateActive: oldState.privateActive,
      platform: oldState.platform,
      paywallActive: oldState.paywallActive,
      prohibitAudioComments: oldState.prohibitAudioComments,
      scoreboardShow: oldState.scoreboardShow,
      gender: oldState.gender,
      hardwarePlatform: oldState.hardwarePlatform,
      videoBufferSecs: oldState.videoBufferSecs,
      competition: oldState.competition,
      teamGuest: oldState.teamGuest,
      clubGuest: oldState.clubGuest
    }

    return JSON.stringify(actualState) !== JSON.stringify(oldStateCheck)
  }

  showFreeTicketList = () => {
    this.refFreeticketList.current?.instance.show()
  }

  render() {
    const goBackToLink = this.props.goBack ? '/recordingplan' + this.props.goBack : '/recordingplan'
    const videoTypeChurch = this.checkEventTypeOfNonCompetitive()
    const eventTypeMeta = eventTypes.find((t) => t.value === this.state.cameraMode.eventType)
    const teamsWasSet = this.state.clubHome !== undefined && this.state.clubGuest !== undefined
    let step: JSX.Element | undefined = undefined
    let title: string | undefined = ''

    const currentContract = this.props.contractContainer.getCurrentContract()
    const currentContractMatchNotAllow = currentContract?.RowKey !== this.state.video?.contractId
    const contractNotSet =
      (currentContractMatchNotAllow && this.state.video?.contractId === '') ||
      this.state.video?.contractId === undefined

    const isStart = new Date(this.state.video?.expectedStartTime).getTime() <= new Date().getTime()
    const isRec =
      this.state.video?.active &&
      this.state.video?.gridStream !== undefined &&
      this.state.video?.gridStream !== ''
    let label = 'Aufnehmen'

    if (isStart) {
      label = isRec ? 'Aufgenommen' : 'Nicht Aufgenommen'
    }

    let stepperConfig = [
      { text: i18n.t('matchEditor.stepCamera.stepperTitel'), icon: videocamIcon },
      { text: i18n.t('matchEditor.stepEvent.stepperTitel'), icon: footballIcon },
      {
        text: i18n.t('matchEditor.stepTeams.stepperTitel'),
        isValid:
          teamsWasSet && (!Boolean(eventTypeMeta?.needsAgeClass) || Boolean(eventTypeMeta?.needsAgeClass)),
        icon: shieldIcon
      },
      { text: i18n.t('matchEditor.stepAds.stepperTitel'), icon: windowIcon }
    ]

    // if videoType church then remove team selection
    if (videoTypeChurch) {
      stepperConfig.splice(2, 1)
    }

    let checkIpCam = false
    if (this.state.dropdownValue.RowKey && !this.props.cameraContainer.state.loadingData) {
      if (
        this.props.cameraContainer
          .getCameraListForCurrentContract()
          .find((itm) => itm.RowKey === this.state.dropdownValue.RowKey)?.defaultVideoProcessing ===
        'passthrough'
      ) {
        checkIpCam = true
      }
    }

    const usedCamera = !this.props.cameraContainer.state.loadingData
      ? this.props.cameraContainer
          .getCameraListForCurrentContract()
          .find((cam) => cam.RowKey === this.state.dropdownValue.RowKey)
      : undefined

    switch (this.state.activeStep) {
      case 0:
        step = (
          <CameraStep
            camera={this.state.dropdownValue.RowKey}
            cameraData={this.state.dropdown}
            onSetCamera={this.handleDropdown}
            selectedField={this.state.selectedField}
            availableFields={this.state.availableFields}
            handleSetFieldName={this.handleSetFieldName}
            disabled={this.state.loadingPost || !this.props.allDataLoaded}
            cameraLoading={Boolean(this.state.dropdownValue?.RowKey)}
            isNew={!this.props.editId}
          />
        )
        title = i18n.t('matchEditor.stepCamera.title')
        break
      case 1:
        step = (
          <EventStep
            isNew={!this.props.editId}
            setPaywall={this.setPaywall}
            paywall={this.state.paywall}
            paywallActive={this.state.paywallActive}
            paywallDataLoaded={this.state.paywallDataLoaded}
            handlePaywallActive={this.handlePaywallActive}
            date={this.state.dateValue}
            onSelectDate={this.handleDate}
            startingTime={this.state.timeValue}
            onSelectStartingTime={this.handleTime}
            duration={this.state.duration}
            onChangeDuration={this.setDuration}
            prohibitAudioComments={this.state.prohibitAudioComments}
            scoreboardShow={this.state.scoreboardShow}
            onChangeDisabledAudioComments={this.handleDisabledAudioComments}
            onChangeDisabledScoreboard={this.handleDisabledScoreboard}
            disabled={this.state.loadingPost || !this.props.allDataLoaded}
            privateActive={this.state.privateActive}
            onChangePrivateActive={this.handlePrivateActive}
            onSetStreamType={this.onSetStreamType}
            onSetEventType={this.onSetEventType}
            onSetCameraType={this.onSetCameraType}
            cameraMode={this.state.cameraMode}
            ifIpCamera={checkIpCam}
            disableTimeDate={this.state.disableTimeDate}
            specialRole={this.checkSpecialRole()}
            connection={this.state.connection}
            onSetConnection={this.onSetConnection}
            enabledConnection={this.state.connectionActive}
            connectionStringCheck={this.state.connectionStringCheck}
            nonCompetitive={this.checkEventTypeOfNonCompetitive()}
            nonCompetitiveTitle={this.state.nonCompetitiveTitle}
            onSetNonCompetitive={this.onSetNonCompetitive}
            onSetVideoBufferSecs={this.onSetVideoBufferSecs}
            videoBufferSecs={this.state.videoBufferSecs}
            acceptEventTypes={this.state.acceptEventTypes}
            acceptEventModes={this.state.acceptEventModes}
            paywallAGB={this.state.paywallAGB}
            handlePaywallAGB={this.setPaywallAGB}
          />
        )
        title = i18n.t('matchEditor.stepEvent.title')
        break
      case videoTypeChurch ? null : 2:
        step = (
          <TeamStep
            competitionContainer={this.props.competitionContainer}
            teamContainer={this.props.teamContainer}
            clubContainer={this.props.clubContainer}
            cityContainer={this.props.cityContainer}
            clubs={this.props.clubs}
            onSetClub={this.onSetClub}
            onSetTeam={this.onSetTeam}
            onSetCompetition={this.setCompetition}
            handleRecordState={this.handleRecordState}
            clubHome={this.state.clubHome}
            clubGuest={this.state.clubGuest}
            teamHome={this.state.teamHome}
            teamGuest={this.state.teamGuest}
            competition={this.state.competition}
            season={this.state.season}
            gender={this.state.gender}
            isNew={!this.props.editId}
            newEventSettings={{
              privateActive: this.state.privateActive,
              date: this.state.date,
              time: this.state.time,
              address: this.state.dropdownValue.address,
              paywallActive: this.state.paywallActive
            }}
          />
        )
        title = i18n.t('matchEditor.stepTeams.title')
        break
      case videoTypeChurch ? 2 : 3:
        step = (
          <SponsorsStep
            selectedAdMaterials={this.state.selectedAdMaterials}
            setSelectedAdMaterials={this.setSelectedAdMaterialIDs}
            adMaterials={this.props.advertisementContainer.getAdMaterialsForCurrentContract()}
            advertisementContainer={this.props.advertisementContainer}
            camera={usedCamera}
          />
        )
        title = i18n.t('matchEditor.stepAds.title')
        break
    }

    return (
      <PageWrapper
        headerTitle={i18n.t('recordingPlan.headerTitleNew')}
        onClick={() => {
          if (this.checkChanges()) {
            this.refConfirmReturnModal.current.handleOpenModal()
          } else {
            this.props.history.push(goBackToLink)
          }
        }}
        style={{ height: '100%' }}
        simpleTopbar
      >
        <StaigeModal
          ref={this.refConfirmReturnModal}
          onConfirm={() => {
            this.props.history.push(goBackToLink)
          }}
          confirmText={i18n.t('matchEditor.changeModal.confirmText')}
          title={i18n.t('matchEditor.changeModal.title')}
          text={i18n.t('matchEditor.changeModal.text')}
        />
        <div className='page-eventEdit'>
          {!this.state.video && (
            <Stepper
              activeStep={this.state.activeStep}
              config={stepperConfig}
              setActiveStep={this.setActiveStep}
              userMayChooseFreely={!this.isNew}
              isNew={Boolean(!this.props.editId)}
            />
          )}
          <div className='eventEdit-step'>
            {this.state.video &&
            currentContractMatchNotAllow &&
            contractNotSet &&
            !currentContract?.disabledSetContractId ? (
              <div className='step-contractWarning'>
                <div>
                  <img src={infoBlueIcon} />
                </div>
                <div className='contractWarning-text'>{i18n.t('recording.informationNotSetContract')}</div>
              </div>
            ) : null}
            {this.state.video &&
            currentContractMatchNotAllow &&
            !contractNotSet &&
            !currentContract?.disabledSetContractId ? (
              <div className='step-contractWarning'>
                <div>
                  <img src={infoBlueIcon} />
                </div>
                <div className='contractWarning-text'>{i18n.t('recording.informationOtherContract')}</div>
              </div>
            ) : null}

            <div className='step-content' style={!this.props.editId ? {} : { gap: 0 }}>
              <div className='content-header' style={!this.props.editId ? {} : { display: 'none' }}>
                <div className='header-title'>{i18n.t('matchEditor.newEvent')}</div>
                <div className='header-subTitle'>{title}</div>
              </div>

              {this.state.video && (
                <>
                  {Boolean(this.props.editId) && this.state.video ? (
                    <div className='box-headerAl'>
                      <div className='headerAl-title'>Event editieren</div>
                      {this.renderCheckBox(
                        isStart,
                        currentContractMatchNotAllow,
                        contractNotSet,
                        label,
                        false
                      )}
                    </div>
                  ) : null}

                  <div className='box-headerAf'>
                    <div className='teams-top'>
                      <div className='top-logos'>
                        <div className='club-logo'>
                          <img
                            src={
                              this.state.clubHome?.thumbnail ||
                              'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
                            }
                          />
                          {
                            <div
                              className='bg'
                              style={
                                this.state.clubHome?.color ? { background: this.state.clubHome.color } : {}
                              }
                            />
                          }
                        </div>
                        <div className='club-logo' style={{ marginLeft: '-16px' }}>
                          <img
                            style={{ zIndex: 3 }}
                            src={
                              this.state.clubGuest?.thumbnail ||
                              'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
                            }
                          />
                          <div className='bg2' />
                          <div
                            className='bg'
                            style={
                              this.state.clubGuest?.color
                                ? { background: this.state.clubGuest.color, zIndex: 3 }
                                : { zIndex: 3 }
                            }
                          />
                        </div>
                      </div>
                      {this.renderCheckBox(
                        isStart,
                        currentContractMatchNotAllow,
                        contractNotSet,
                        label,
                        Boolean(this.props.editId)
                      )}
                    </div>
                    <div className='teams-title'>
                      {this.state.clubHome?.name} {this.state.video.scoreA || '0'}-
                      {this.state.video.scoreB || '0'} {this.state.clubGuest?.name}
                    </div>
                    <div className='teams-label'>
                      <StaigeBadge
                        active={this.state.video.contentType === 'broadcast'}
                        color='red'
                        title={`event`}
                      />
                      <StaigeBadge
                        active={this.state.video.contentType === 'training'}
                        color='white'
                        title={`training`}
                      />
                      <StaigeBadge
                        active={this.state.video.contentType === 'test'}
                        color='white'
                        title={`test`}
                      />
                      <StaigeBadge
                        active={this.state.video?.teamAAge !== undefined}
                        title={this.state.video.teamAAge}
                      />
                    </div>
                  </div>

                  <div className={'content-shareXX'} style={{ marginTop: 24 }}>
                    <div className='share-left'>
                      {this.props.paywallContainer.state.paywallFreeTickets.length > 0 ? (
                        <a className='left-freeCard' onClick={() => this.showFreeTicketList()}>
                          {i18n.t('recording.freecard')}
                        </a>
                      ) : null}
                    </div>
                    <div className='share-quickBtn'>
                      <StaigeButton
                        style='ghost'
                        label={'Embed Code'}
                        onClick={() => {
                          this.setState({ embedCodeOpen: !this.state.embedCodeOpen })
                        }}
                      />
                      <StaigeButton
                        style='ghost'
                        label={i18n.t('recording.staigeLink')}
                        linkTo={'https://staige.tv/video/' + this.state.video.RowKey + '/'}
                        externLink
                        iconMargin='0 -4px 0 0'
                        iconRight
                        disabled={!this.state.video.active}
                        target='_blank'
                      />
                      <StaigeButton
                        style='secondary'
                        label={i18n.t('recording.tagtool')}
                        linkTo={'/tagging/' + this.state.video.RowKey}
                        iconMargin='0 -4px 0 0'
                        iconRight
                      />
                    </div>
                  </div>

                  <EmbedCode active={this.state.embedCodeOpen} id={this.state.video.RowKey} />
                </>
              )}

              <div className={`content-main ${this.state.video && 'content-mainEdit'}`}>
                {this.state.video && (
                  <div className='main-tab'>
                    <div className='tab-textBlock'>
                      <div className='textBlock-icon'>
                        <img src={tagIcon} />
                      </div>
                      <div className='textBlock-title'>{this.state.video.RowKey}</div>
                    </div>

                    <div className='tab-textBlock'>
                      <div className='textBlock-icon'>
                        <img src={eyeIcon} />
                      </div>
                      <div className='textBlock-title'>
                        {this.state.video.privateActive ? 'privat' : 'öffentlich'}
                      </div>
                    </div>

                    <div className='tab-textBlock'>
                      <div className='textBlock-icon'>
                        <img src={eventIcon} />
                      </div>
                      <div className='textBlock-title'>{this.state.date}</div>
                    </div>

                    <div className='tab-textBlock'>
                      <div className='textBlock-icon'>
                        <img src={scheduleIcon} />
                      </div>
                      <div className='textBlock-title'>{this.state.time}</div>
                    </div>

                    <div className='tab-textBlock mobile'>
                      <div className='textBlock-icon'>
                        <img src={locatioOnIcon} />
                      </div>
                      <div className='textBlock-title'>
                        {this.state.video.address || this.state.video.field}
                      </div>
                    </div>

                    <div
                      className='tab-textBlock mobile'
                      style={!this.state.video.paywall ? { display: 'none' } : {}}
                    >
                      <div className='textBlock-icon'>
                        <img src={localActivityIcon} />
                      </div>
                      <div className='textBlock-title'>Paywall</div>
                    </div>
                  </div>
                )}

                {this.state.video && (
                  <Stepper
                    activeStep={this.state.activeStep}
                    config={stepperConfig}
                    setActiveStep={this.setActiveStep}
                    userMayChooseFreely={!this.isNew}
                    isNew={Boolean(!this.props.editId)}
                  />
                )}

                {step}
              </div>
            </div>
          </div>
        </div>

        {this.state.video ? (
          <div className='bottom-quickBar'>
            <StaigeButton
              label={i18n.t('general.save')}
              style='white'
              iconMargin='0 -4px 0 4px'
              onClick={this.handleAcceptEdit}
              disabled={
                this.state.loadingPost ||
                !this.props.allDataLoaded ||
                (currentContractMatchNotAllow && !contractNotSet)
              }
            />
          </div>
        ) : (
          <div className='bottom-quickBar'>
            {this.state.activeStep > 0 && (
              <StaigeButton
                label={i18n.t('general.back')}
                style='secondary'
                onClick={this.goToPreviousStep}
              />
            )}

            <StaigeButton
              label={
                this.state.activeStep === 3 ||
                (this.checkEventTypeOfNonCompetitive() && this.state.activeStep === 2)
                  ? i18n.t('general.save')
                  : i18n.t('general.continue')
              }
              style='white'
              icon={
                this.state.activeStep === 3 ||
                (this.checkEventTypeOfNonCompetitive() && this.state.activeStep === 2)
                  ? undefined
                  : arrowIcon
              }
              iconRight
              iconMargin='0 -4px 0 4px'
              onClick={
                this.state.activeStep === 3 ||
                (this.checkEventTypeOfNonCompetitive() && this.state.activeStep === 2)
                  ? this.props.editId === undefined
                    ? this.handleAccept
                    : this.handleAcceptEdit
                  : this.goToNextStep
              }
              disabled={
                this.state.loadingPost ||
                !this.props.allDataLoaded ||
                (!this.checkEventTypeOfNonCompetitive() && this.state.activeStep === 2 && !teamsWasSet) ||
                (this.state.connectionActive && this.state.connectionStringCheck)
              }
            />
          </div>
        )}

        <mobiscroll.Popup
          ref={this.refFreeticketList}
          display='center'
          buttons={[{ text: i18n.t('general.cancel'), handler: 'cancel' }]}
        >
          <div className='mbsc-padding'>
            <div className='mbsc-align-center'>
              <h3>{i18n.t('recording.freecard')}</h3>
            </div>

            {this.props.paywallContainer.state.paywallFreeTickets.length > 0
              ? this.props.paywallContainer.state.paywallFreeTickets.map((itm, i) => {
                  const number = i + 1
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '20px',
                        backgroundColor: '#0000001C',
                        marginBottom: '2px'
                      }}
                    >
                      {i18n.t('recording.ticket')}{' '}
                      {number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:{' '}
                      <p style={{ paddingLeft: '10px' }}>{itm['Trans-Nr']}</p>
                    </div>
                  )
                })
              : null}
            <div style={{ width: '250px' }} />
          </div>
        </mobiscroll.Popup>
      </PageWrapper>
    )
  }

  get isNew() {
    return !this.props.editId
  }
}

export default withAdvertisementContainer(withCameraContainer(MatchEditor))
