import React, { useEffect } from 'react'
import AiswSpinner from '../../pages/helper/aiswSpinner/aiswSpinner'

import { GridPlayerSetup } from '@soccerwatch/common'
import { LivekitContainer } from '../../services/livekitContainer'

import './player.scss'

type PanoramaPlayerProps = {
  cameraId: string
  currentCorners: number[]
  currentHomePosition: { grid_pos: number[]; fov: number }
  setCurnerPoints: (curnerPoints: Array<number>) => void
  setHomePosition: (homePosition: Array<number>) => void
  setFov: (fov: number) => void
  livekitContainer: LivekitContainer
}

export const PanoramaPlayer = (props: PanoramaPlayerProps) => {
  const panoPlayer = React.createRef<any>()
  const [connected, setConnected] = React.useState<boolean>(false)
  const [fullscreen, setFullscreen] = React.useState<boolean>(false)
  const [hello, setHello] = React.useState<any>()
  const [cornerPoints] = React.useState<number[]>([...props.currentCorners])

  useEffect(() => {
    props.livekitContainer.livekitConnectionStart(props.cameraId)
  }, [])

  useEffect(() => {
    const { connected, element, urlElement } = props.livekitContainer.state
    if (connected && element) {
      createPlayer('player', element)
    }

    if (connected && urlElement) {
      createPlayer('img', urlElement)
    }

    if (!connected) {
      if (panoPlayer.current) {
        while (panoPlayer.current.firstChild) {
          panoPlayer.current.removeChild(panoPlayer.current.firstChild)
        }
      }
    }
  }, [props.livekitContainer.state.connected])

  const createPlayer = (type: 'player' | 'img', element: HTMLMediaElement | string) => {
    if (panoPlayer.current || true) {
      const playerElement = document.createElement('staige-panorama-player') as any

      let sourceType = {}

      if (type === 'player') {
        sourceType = {
          useSource: 'player',
          playerSource: {
            video: element,
            cornerData: props.currentCorners
          }
        }
      } else {
        sourceType = {
          useSource: 'image',
          imageSource: {
            sourceImageURI: element,
            cornerData: props.currentCorners
          }
        }
      }

      if (element) {
        let trackOptions: GridPlayerSetup = {
          useSource: 'player',
          fieldSizeMeter: [60, 40],
          enableFieldMaskEdit: true,
          cameraId: props.cameraId,
          allowVr: false,

          ...sourceType,

          enableHomePosition: true,
          enableHomePositionEdit: true,
          // @ts-ignore
          homePosition: props.currentHomePosition.grid_pos,
          homePositionFieldOfView: props.currentHomePosition.fov
        }

        playerElement.options = trackOptions
        playerElement.addEventListener('hello', async (event: any) => {
          setHello(event.detail)
        })

        panoPlayer.current?.append(playerElement as unknown as Node)

        playerElement.addEventListener('onSetCornerPoints', async (event: any) => {
          if (String(event.detail) !== String(cornerPoints)) {
            props.setCurnerPoints(event.detail)
          }
        })

        playerElement.addEventListener('onSetHomePosition', async (event: any) => {
          if (String(event.detail) !== String(props.currentHomePosition.grid_pos)) {
            props.setHomePosition(event.detail)
          }
        })

        setConnected(true)
      }
    }
  }

  const handleChange = (event: any) => {
    hello.setHomePositionFieldOfView(event.target.value)
    hello.homePositionService.active = true
    props.setFov(Number(event.target.value))
  }

  return (
    <div className='comp-panoPlayer'>
      {!connected && (
        <div className='player-loading'>
          <AiswSpinner />
          <div className='loading-text'>Connection</div>
        </div>
      )}

      <div
        className={fullscreen ? 'panoPlayer-b panoPlayer-fullscreen' : 'panoPlayer-b'}
        style={!connected ? { display: 'none' } : {}}
      >
        <button className='full-button' onClick={() => setFullscreen(!fullscreen)}>
          <svg fill='#fff' height='34px' width='34px' viewBox='0 0 512 512'>
            <g id='SVGRepo_bgCarrier'></g>
            <g id='SVGRepo_tracerCarrier'></g>
            <g id='SVGRepo_iconCarrier'>
              <g>
                <g>
                  <path d='M0,0v512h512V0H0z M477.867,477.867H34.133V34.133h443.733V477.867z'></path>
                </g>
              </g>
              <g>
                <g>
                  <polygon points='126.533,102.4 199.111,102.4 199.111,68.267 68.267,68.267 68.267,199.111 102.4,199.111 102.4,126.538 198.422,222.558 222.556,198.423 '></polygon>
                </g>
              </g>
              <g>
                <g>
                  <polygon points='222.557,313.581 198.422,289.445 102.4,385.467 102.4,312.889 68.267,312.889 68.267,443.733 199.111,443.733 199.111,409.6 126.538,409.6 '></polygon>
                </g>
              </g>
              <g>
                <g>
                  <polygon points='409.6,312.889 409.6,385.467 313.578,289.444 289.444,313.578 385.462,409.6 312.889,409.6 312.889,443.733 443.733,443.733 443.733,312.889 '></polygon>
                </g>
              </g>
              <g>
                <g>
                  <polygon points='312.889,68.267 312.889,102.4 385.467,102.4 289.444,198.423 313.578,222.558 409.6,126.538 409.6,199.111 443.733,199.111 443.733,68.267 '></polygon>
                </g>
              </g>
            </g>
          </svg>
        </button>

        <div className='panoPlayer-range'>
          <label>
            {'Fov: ' + props.currentHomePosition.fov}
            <input
              style={{ marginLeft: '10px' }}
              type='range'
              min='5'
              max='90'
              value={props.currentHomePosition.fov}
              onChange={handleChange}
            />
          </label>
        </div>

        <div style={{ height: '100%' }} ref={panoPlayer} />
      </div>
    </div>
  )
}
