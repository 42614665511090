import React from 'react'
import mobiscroll from '@mobiscroll/react'
import PageWrapper from '../../helper/PageWrapper'
import i18 from 'i18next'
import field from './field.svg'
import StaigeButton from '../../components/styles/Button'
import i18n from '../../languages/i18n'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { RouteComponentProps } from 'react-router'
import { isAxiosError } from '../../helper/UtilityFunctions'
import { BrowserInfoContainer } from '../../services/browserInfoContainer'
import { CameraData, Role } from '@soccerwatch/common'
import { searchAndSortGetCallAs } from '../../services/serviceHelper'
import { getCameraCornerPointsAll, getCameraCornerPointsFieldName } from '../../api/api-camera'
import { PanoramaPlayer } from '../../components/panoramaPlayer/player'
import { LivekitContainer } from '../../services/livekitContainer'

import './FieldMask.scss'

const defaultCorners = [1713, 1274, 7, 1289, 3823, 9, 2127, 2539]
const defaultHomePosition = [2926, 3026]
const defaultFov = 60

type FieldMaskPageProps = RouteComponentProps & {
  cameraId: string
  camera: CameraData
  contractContainer: ContractContainer
  fieldMask?: {
    lastCalibration: unknown
  }
  browserContainer: BrowserInfoContainer
  fieldName?: string
  isNew?: boolean
  livekitContainer: LivekitContainer
}

type FieldMaskStateType = {
  fieldNames: string
  currentHomePosition: { grid_pos: number[]; fov: number }
  currentCorners: number[]
  currentFieldName: string

  save: boolean
  uploadCurners: boolean
  setYaml: boolean
}

export default class FieldMaskPage extends React.Component<FieldMaskPageProps, FieldMaskStateType> {
  constructor(props: FieldMaskPageProps) {
    super(props)
    this.state = {
      fieldNames: 'default',
      currentCorners: defaultCorners,
      currentHomePosition: { grid_pos: defaultHomePosition, fov: defaultFov },
      currentFieldName: '',
      save: false,
      uploadCurners: false,
      setYaml: false
    }
  }

  componentDidMount = () => {
    this.getCornerPoints()
  }

  getCornerPoints = async () => {
    try {
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      const res = await getCameraCornerPointsAll(this.props.cameraId, callAs)
      if (res.status === 200) {
        let fieldNames: string = this.props.fieldName || ''
        let fieldCorners: number[] = []
        let homePositions: { grid_pos: number[]; fov: number } = this.state.currentHomePosition

        res.data.forEach((dataset) => {
          if (!dataset.Corners.corners.length) {
            dataset.Corners.corners = JSON.parse(JSON.stringify(defaultCorners))
          }
          if (this.props.fieldName === dataset.fieldName) {
            fieldNames = dataset.fieldName
            fieldCorners = dataset.Corners.corners

            //@ts-ignore
            const homePosition: { grid_pos: number[]; fov: number } = dataset.HomePosition

            homePositions.fov = homePosition?.fov && homePosition.fov > 5 ? homePosition.fov : defaultFov
            homePositions.grid_pos =
              homePosition?.grid_pos && homePosition.grid_pos.length === 2
                ? homePosition.grid_pos
                : defaultHomePosition
          }
        })

        this.setState({
          currentFieldName: fieldNames,
          currentCorners: fieldCorners.length <= 0 ? defaultCorners : fieldCorners,
          currentHomePosition: homePositions,
          setYaml: true
        })
        return
      }
      console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', res.status)
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', err.response?.status)
      } else {
        console.warn('An unknown error occurred:', err)
      }
    }
  }

  renderBottomBtns = () => {
    return (
      <StaigeButton
        label={this.props.isNew ? i18n.t('general.create') : i18n.t('general.save')}
        style='white'
        iconMargin='0 -4px 0 4px'
        disabled={
          !this.state.save ||
          this.state.uploadCurners ||
          this.state.currentFieldName === '' ||
          this.state.currentFieldName === undefined
        }
        onClick={() => this.uploadCornerPoints()}
      />
    )
  }

  setCurnerPoints = (curnerPoints: Array<number>) => {
    this.setState({
      currentCorners: curnerPoints,
      save: true
    })
  }

  setHomePosition = (homePosition: Array<number>) => {
    this.setState({
      currentHomePosition: { grid_pos: homePosition, fov: this.state.currentHomePosition.fov },
      save: true
    })
  }

  setFov = (fov: number) => {
    this.setState({
      currentHomePosition: { grid_pos: this.state.currentHomePosition.grid_pos, fov: fov },
      save: true
    })
  }

  uploadCornerPoints = async () => {
    this.setState({ uploadCurners: true })
    const { currentFieldName, currentCorners, currentHomePosition } = this.state

    let newCorner: Array<number> = []
    for (const [key, value] of Object.entries(currentCorners)) {
      newCorner.push(Math.round(value))
    }

    let body = { Corners: { corners: newCorner }, HomePosition: currentHomePosition }
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const res = await getCameraCornerPointsFieldName(this.props.cameraId, currentFieldName, body, callAs)
    this.setState({ uploadCurners: false })
    if (res.status === 'Ok') {
      this.props.history.push(`/cameramonitor/${this.props.cameraId}`)
      return
    }
    console.error('Could not save Fieldmask:', res)
    alert(i18.t('matchfieldCalibration.saveError'))
  }

  render() {
    const { save, currentFieldName, currentCorners, currentHomePosition, setYaml } = this.state

    return (
      <PageWrapper
        headerTitle={`${i18n.t('fieldMask.headerTitleLoad')} ${this.props.camera?.hardwarePlatform} – ${
          this.props.camera?.RowKey
        }`}
        linkTo={`/cameramonitor/${this.props.camera.RowKey}`}
        simpleTopbar
        bottomBar={save ? this.renderBottomBtns : undefined}
      >
        <div>
          <div className='page-fieldMask'>
            <div className='fieldMask-container'>
              <div className='container-content'>
                <div className='content-header'>
                  <div className='header-title'>
                    {this.props.isNew
                      ? i18n.t('fieldMask.headerTitleNew')
                      : i18n.t('fieldMask.headerTitleEdit')}
                  </div>
                </div>
                <div className='content-bottom'>
                  <div className='bottom-input'>
                    <mobiscroll.Input
                      type={'text'}
                      value={currentFieldName}
                      disabled={!this.props.isNew}
                      onChange={(v) =>
                        this.setState({
                          currentFieldName: String(v.target.value).trim()
                        })
                      }
                      placeholder='Hauptfeld - 11er-Fußball...'
                      valid={true}
                    >
                      {i18n.t('fieldMask.inputFieldMaskTitle')}
                    </mobiscroll.Input>
                  </div>
                  <div className='bottom-fieldmaskContainer'>
                    <div className='fieldmaskContainer-header'>
                      <div className='header-title'>{i18n.t('fieldMask.fieldmaskContainerTitle')}</div>
                    </div>
                    <div className='fieldmaskContainer-content'>
                      <div className='content-interactiv'>
                        <div className='interactiv-field'>
                          <img src={field} />
                        </div>
                        <div className='interactiv-text'>{i18n.t('fieldMask.interactiveText')}</div>
                      </div>
                      <div className='content-prev'>
                        {setYaml && (
                          <PanoramaPlayer
                            cameraId={this.props.cameraId}
                            currentCorners={currentCorners}
                            currentHomePosition={currentHomePosition}
                            setCurnerPoints={this.setCurnerPoints}
                            setHomePosition={this.setHomePosition}
                            setFov={this.setFov}
                            livekitContainer={this.props.livekitContainer}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
